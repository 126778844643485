import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "link",
        "card",
    ]

    connect() {
        this.cardTarget.addEventListener('click', () => {
            this.linkTarget.click();
        });
    }
}
