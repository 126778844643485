import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    async connect() {
        const { default: Trix } = await import("trix");

        Trix.config.textAttributes.underline = {
            style: {
                textDecoration: "underline",
            },
            inheritable: true,
            parser: (element) => {
                const style = window.getComputedStyle(element);

                // Text decoration can contain more than only "underline"
                return style.textDecoration === "underline" ||
                    style.textDecorationLine === "underline";
            }
        }

        addEventListener("trix-file-accept", this.preventDefault );
    }

    disconnect() {
        removeEventListener("trix-file-accept", this.preventDefault );
    }

    preventDefault( event ) {
        event.preventDefault();
    }
}
