import { Controller } from "@hotwired/stimulus"

export default class HelpMeController extends Controller {
    static targets = [ 'trigger', 'text' ];
    static values = {
        inputId: String,
        stepType: String,
    }

    connect() {
        setTimeout( this.highlight.bind(this), 20 * 1000 );
    }

    toggleText() {
        this.textTarget.classList.toggle('help-me__text--shown');
    }

    isInputEmpty() {
        const stepType = this.stepTypeValue;

        switch ( stepType ) {
            case 'do-dont':
                const items = document.querySelectorAll('.do-dont__list-item');

                return items.length === 0;
            default:
            case 'text-input':
                const input = document.getElementById(this.inputIdValue);

                return input.value === '';
        }

        return true;
    }

    highlight() {
        if ( this.textTarget.classList.contains( 'help-me__text--shown' ) ) {
            return;
        }

        if ( ! this.isInputEmpty() ) {
            return;
        }

        this.element.classList.add('help-me--highlight');
    }
}
