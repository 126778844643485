import { connectStreamSource, disconnectStreamSource } from "@hotwired/turbo";
import consumer from "./consumer";

// We need an object that supports addEventListener, removeEventListener & dispatchEvent.
const emitter = document.createDocumentFragment();

consumer.subscriptions.create("TurboChannel", {
  connected() {
    connectStreamSource(emitter)
  },

  disconnected() {
    disconnectStreamSource(emitter)
  },

  received(data) {
    const event = new MessageEvent("message", {data});
    emitter.dispatchEvent(event)
  }
});
