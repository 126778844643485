import { Controller } from "@hotwired/stimulus"
import { isString } from "lodash";

const SHORT_HEX_REGEX = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
const LONG_HEX_REGEX  = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;

const RGB_REGEX = /^[^\d]*(\d+)[^\d]+(\d+)[^\d]+(\d+)[^\d]*$/;
const CMYK_REGEX = /^[^\d]*(\d+)[^\d]+(\d+)[^\d]+(\d+)[^\d]+(\d+)[^\d]*$/;

export default class extends Controller {
    static targets = [
        "definedIn",
        "definedInFieldset",
        "preview",
        "hex",
        "red",
        "green",
        "blue",
        "cyan",
        "magenta",
        "yellow",
        "key",
        "submitButton"
    ]

    connect() {
        // Remove the visual version of definedIn
        this.definedInFieldsetTarget.remove();

        // Show the preview, because it only works with JS
        this.previewTarget.style.display = 'block';

        this.setHexColor();
        this.setCmyk();
        this.renderButtonDisabled();
    }

    hexToRgb( hex ) {
        const shortVersion = SHORT_HEX_REGEX.exec( hex );

        if ( shortVersion !== null ) {
            return {
                red: parseInt(shortVersion[1] + shortVersion[1], 16),
                green: parseInt(shortVersion[2] + shortVersion[2], 16),
                blue: parseInt(shortVersion[3] + shortVersion[3], 16),
            }
        }

        const longVersion = LONG_HEX_REGEX.exec( hex );
        if ( longVersion !== null ) {
            return {
                red: parseInt(longVersion[1], 16),
                green: parseInt(longVersion[2], 16),
                blue: parseInt(longVersion[3], 16),
            }
        }

        return null;
    }

    componentToHex( component ) {
        const hexComponent = component.toString(16);

        return hexComponent.length === 1 ? "0" + hexComponent : hexComponent;

    }

    rgbToHex( { red, green, blue } ) {
        return this.componentToHex( red ) + this.componentToHex( green ) + this.componentToHex( blue );
    }

    setHexColor() {
        this.hexColor = this.hexTarget.value;

        const rgb = this.hexToRgb( this.hexColor );

        if ( rgb !== null ) {
            this.renderPreview( rgb );
            this.renderRgb( rgb );
        }
    }

    setRgb() {
        const red = parseInt(this.redTarget.value || '0', 10);
        const green = parseInt(this.greenTarget.value || '0', 10);
        const blue = parseInt(this.blueTarget.value || '0', 10);

        const rgb = { red, green, blue };

        this.renderPreview( rgb );
        this.renderHex( rgb );
        this.renderButtonDisabled();
    }

    setCmyk() {
        if ( this.isCmykComplete() ) {
            this.renderDefinedIn( 'cmyk' );
        } else {
            this.renderDefinedIn( 'rgb' );
        }
        this.renderButtonDisabled();
    }

    isCmykComplete() {
        return !!( this.cyanTarget.value &&
            this.magentaTarget.value &&
            this.yellowTarget.value &&
            this.keyTarget.value );
    }

    isRgbComplete() {
        return !!( this.redTarget.value &&
            this.greenTarget.value &&
            this.blueTarget.value );
    }

    isFormComplete() {
        return this.isCmykComplete() || this.isRgbComplete();
    }

    renderDefinedIn( definedIn ) {
        this.definedInTarget.value = definedIn;
    }

    renderHex( rgb ) {
        this.hexTarget.value = this.rgbToHex( rgb );
    }

    renderRgb({red, green, blue} ) {
        this.redTarget.value = red;
        this.greenTarget.value = green;
        this.blueTarget.value = blue;

        this.renderButtonDisabled();
    }

    renderCmyk({cyan, magenta, yellow, key}) {
        this.cyanTarget.value = cyan;
        this.magentaTarget.value = magenta;
        this.yellowTarget.value = yellow;
        this.keyTarget.value = key;

        this.renderButtonDisabled();
    }

    renderPreview( rgb ) {
        this.previewTarget.style.backgroundColor = '#' + this.rgbToHex( rgb );
    }

    renderButtonDisabled() {
        if ( this.isFormComplete() ) {
            this.submitButtonTarget.removeAttribute( 'disabled' );
        } else {
            this.submitButtonTarget.setAttribute( 'disabled', 'disabled' );
        }
    }

    detectPastedRgb( event ) {
        const paste = (event.clipboardData || window.clipboardData).getData('text');

        if ( isString( paste ) ) {
            const rgbMatch = RGB_REGEX.exec( paste );

            if ( rgbMatch !== null ) {
                const rgb = {
                    red: parseInt( rgbMatch[1], 10 ),
                    green: parseInt( rgbMatch[2], 10 ),
                    blue: parseInt( rgbMatch[3], 10 ),
                };

                // Make sure the content is already pasted by setting a timeout for 0 seconds
                setTimeout(() => {
                    this.renderHex(rgb);
                    this.renderRgb(rgb);
                    this.renderPreview(rgb);
                }, 0);
            }
        }
    }

    detectPastedCmyk( event ) {
        const paste = (event.clipboardData || window.clipboardData).getData('text');

        if ( isString( paste ) ) {
            const cmykMatch = CMYK_REGEX.exec( paste );

            if ( cmykMatch !== null ) {
                const cmyk = {
                    cyan: parseInt( cmykMatch[1], 10 ),
                    magenta: parseInt( cmykMatch[2], 10 ),
                    yellow: parseInt( cmykMatch[3], 10 ),
                    key: parseInt( cmykMatch[4], 10 ),
                };

                // Make sure the content is already pasted by setting a timeout for 0 seconds
                setTimeout(() => {
                    this.renderCmyk(cmyk);
                }, 0);
            }
        }
    }
}
