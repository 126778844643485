import { Controller } from "@hotwired/stimulus"
import MicroModal from 'micromodal';

export default class extends Controller {
    static targets = [ "modalTitle" ]

    // It is possible to have JavaScript enabled, but be on a page that should render inside a modal.
    // Because of that, only open the modal when we are actually inside it.
    isInsideModal() {
        const container = this.modalTitleTarget.closest('#modal-container');

        return container !== null;
    }

    connect() {
        if ( ! this.isInsideModal() ) {
            return;
        }

        // Set an ID so the labelledby on the modal works.
        this.modalTitleTarget.id = 'modal-title';

        // Call `show` here to make MicroModal put the focus correctly.
        MicroModal.show('modal-container', {
            openClass: 'modal-container--open',
            disableScroll: true,
        })
    }
}
