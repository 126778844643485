import { Controller } from "@hotwired/stimulus";
import { computePosition, flip, shift, offset } from "@floating-ui/dom";

const KEYCODES_ESC = 27;

export default class extends Controller {
    static targets = [ "dropdown", "button" ]

    async connect() {
        this.close = this.close.bind( this );
        this.closeOnTargetOutside = this.closeOnTargetOutside.bind( this );
        this.closeWithKey = this.closeWithKey.bind( this );

        this.close();

        document.addEventListener( 'click', this.closeOnTargetOutside );
        document.addEventListener( 'keydown', this.closeWithKey );
    }

    disconnect() {
        document.removeEventListener( 'click', this.closeOnTargetOutside );
        document.removeEventListener( 'keydown', this.closeWithKey );
    }

    closeOnTargetOutside({ target }) {
        const { dropdownTarget: dropdown, buttonTarget: button } = this;

        if ( dropdown.contains( target ) || button.contains( target ) ) {
            return;
        }

        this.close();
    }

    stopPropagation( event ) {
        event.stopPropagation();
    }

    async position() {
        const { dropdownTarget: dropdown, buttonTarget: button } = this;

        const { x, y } = await computePosition( button, dropdown, {
            placement: 'bottom-start',
            middleware: [ offset( 5 ), flip(), shift( { padding: 10 } ) ]
        } );

        Object.assign( dropdown.style, {
            left: `${x}px`,
            top: `${y}px`,
        } );
    }

    close() {
        const { dropdownTarget: dropdown, buttonTarget: button } = this;

        dropdown.style.display = 'none';
        dropdown.setAttribute( 'aria-hidden', true );
        button.setAttribute('aria-expanded', false );
    }

    open () {
        const { dropdownTarget: dropdown, buttonTarget: button } = this;

        this.position();

        dropdown.style.display = 'block';
        dropdown.setAttribute( 'aria-hidden', false );
        button.setAttribute('aria-expanded', true);
    }

    isOpen() {
        const { buttonTarget: button } = this;

        return 'true' === button.getAttribute('aria-expanded');
    }

    toggle() {
        this.isOpen() ? this.close() : this.open();
    }

    closeWithKey( event ) {
        const { keyCode } = event;
        const { buttonTarget: button } = this;

        if ( this.isOpen() && keyCode === KEYCODES_ESC ) {
            this.close();

            // Prevent focus loss.
            button.focus();
        }
    }
}
