import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = []

    connect() {
        this.observer = new IntersectionObserver( entries => {
            entries.forEach( entry => {
                if ( entry.intersectionRatio > 0 ) {
                    this.element.classList.add( 'active-in-view' );
                } else {
                    this.element.classList.remove( 'active-in-view' );
                }
            } )
        } );

        const id = this.element.href.split('#')[1];
        const heading = document.getElementById( id );

        this.observer.observe( heading );
    }

    disconnect() {
        this.observer.disconnect();
    }
}
