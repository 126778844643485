import { Controller } from "@hotwired/stimulus"
import MicroModal from 'micromodal';

export default class extends Controller {
    static targets = [ "modalContainer", "modal" ]

    initialize() {
        this.handleStream = this.handleStream.bind( this );
        this.close = this.close.bind( this );
    }

    isOpen() {
        return this.modalContainerTarget.getAttribute('aria-hidden') === 'false';
    }

    connect() {
        document.addEventListener( 'turbo:render', this.close );
        document.addEventListener( 'turbo:before-stream-render', this.handleStream );
        document.addEventListener('turbo:before-cache', this.cleanModalContent.bind(this));
    }

    disconnect() {
        document.removeEventListener( 'turbo:render', this.close );
        document.removeEventListener( 'turbo:before-stream-render', this.handleStream );
        document.removeEventListener('turbo:before-cache', this.cleanModalContent.bind(this));
    }

    handleStream({ target: eventTarget }) {
        // If the modal is not open, this this stream is definitely not for us.
        if ( ! this.isOpen() ) {
            return;
        }

        const { action, target } = eventTarget;

        // If form submitting failed, the modal will be updated, so let's scroll to the top in that case.
        if ( action === 'update' && target === 'modal' ) {
            this.modalTarget.scrollTo(0, 0);

        // When something other than the modal is updated, we definitely need to close the modal.
        } else if ( target !== 'modal' ) {
            MicroModal.close('modal-container');

            // Reset frame, so if we load the same URL it is cleaned up.
            this.modalTarget.src = '';
        }
    }

    close( event ) {
        if ( this.isOpen() ) {
            // Prevent Turbo from navigating when we only want to close the modal.
            event.preventDefault();

            MicroModal.close('modal-container');
        }
    }

    open( event ) {
        // Find the link that opened the modal
        const clickedLink = event.target.closest('[data-turbo-frame="modal"]');
        const clickedUrl = clickedLink?.href;

        // If src is already equal to the clicked URL the modal must already have the right content.
        // So we can show it immediately.
        if ( clickedUrl && clickedUrl === this.modalTarget.src ) {
            MicroModal.show('modal-container', {
                openClass: 'modal-container--open',
                disableScroll: true,
            })
        }
    }

    cleanModalContent() {
        this.modalTarget.innerHTML = '';
        this.modalTarget.src = '';
    }
}
