import { Controller } from "@hotwired/stimulus"

export default class GalleryController extends Controller {
    static values = {
        selector: String,
    }

    async connect() {
        await this.initPhotoSwipe();
    }

    async initPhotoSwipe() {
        let PhotoSwipeLightbox = await import('photoswipe/lightbox');
        PhotoSwipeLightbox = PhotoSwipeLightbox.default;

        await import('photoswipe/dist/photoswipe.css');

        const lightbox = new PhotoSwipeLightbox({
            gallery: this.element,
            children: this.hasSelectorValue ? this.selectorValue : 'a',
            pswpModule: () => import('photoswipe'),
        });
        lightbox.init();
    }
}
