import { Controller } from "@hotwired/stimulus"

export default class HelpMeController extends Controller {
    static targets = [ 'submit' ];

    connect() {
        this.submitTarget.style.display = 'none';
    }

    preventDefault( event ) {
        event.preventDefault();
        event.stopPropagation();
    }

    submit() {
        this.submitTarget.click();
    }
}
