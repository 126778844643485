import { Controller } from "@hotwired/stimulus"

const matchIllegalChars = /[^a-z0-9-]/g
const matchSpaces = /[^\w]/g

export default class extends Controller {
    static targets = [ "company", "slug" ]
    static values  = { connected: Boolean }

    fillSlug() {
        if ( this.connectedValue ) {
            let companyName = this.companyTarget.value;

            companyName = companyName.toLowerCase();
            companyName = companyName.replace(matchSpaces, '-')
            companyName = companyName.replace(matchIllegalChars, '');

            this.slugTarget.value = companyName;
        }
    }

    disconnectSlug() {
        this.connectedValue = false;
    }
}
