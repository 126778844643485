import { Controller } from "@hotwired/stimulus"

export default class FlashController extends Controller {

    hide() {
        this.element.style.maxHeight = this.element.clientHeight + 'px';
        // Wait for a paint to occur, so we ensure the transition can happen.
        setTimeout(() => {
            this.element.style.maxHeight = 0;
        }, 0);
    }
}
