import { Controller } from "@hotwired/stimulus"

export default class DropzoneController extends Controller {
    static targets = [
        'input',
        'inputContainer',
        'zone',
        'form',
        'submit',
        'selectFileText',
        'selectedFileText',
        'selectedFileName',
    ];

    connect() {
        this.inputContainerTarget.style.display = 'none';
        this.zoneTarget.classList.remove('dropzone--no-js');

        if ( this.hasSubmitTarget ) {
            this.submitTarget.style.display = 'none';
            // Rails-UJS disables the submit button by default after submitting. This prevents that.
            delete this.submitTarget.dataset.disableWith;
        }
    }

    preventDefault( event ) {
        event.preventDefault();
        event.stopPropagation();
    }

    highlight() {
        this.zoneTarget.classList.add('dropzone--hovering-files');
    }

    removeHighlight() {
        this.zoneTarget.classList.remove('dropzone--hovering-files');
    }

    setFiles( event ) {
        this.inputTarget.files = event.dataTransfer.files;

        this.submitForm();
    }

    submitForm() {
        if ( this.hasSubmitTarget ) {
            this.submitTarget.click();
        } else {
            const name = this.inputTarget.files[0].name;

            this.selectedFileTextTarget.style.display = 'block';
            this.selectedFileNameTarget.innerText = name;
            this.selectFileTextTarget.style.display = 'none';
        }
    }

    openFileSelector() {
        this.inputTarget.click();
    }
}
